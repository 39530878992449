/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// swiper css
@import "~swiper/scss";
@import "~@ionic/angular/css/ionic-swiper";

// Custom CSS
.error-message-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  margin-bottom: 10px;

  white-space: pre-line;
}

.error-input-message {
  display: flex;
  justify-content: center;
  align-items: center;

  color: red;
  margin: 2px 0px 3px 0px;

  ion-icon {
    min-width: 16px;
    min-height: 16px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.custom-table {
  .table-header {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    color: whitesmoke;
    min-height: 55px;
    background-color: #6574cf;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    margin-bottom: 2px;
  }

  .table-row {
    min-height: 55px;
    border-radius: 3px;
    margin-bottom: 2px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);

    ion-input {
      border: solid 1px lightgray;
      border-radius: 10px;
    }
  }
}

.event-detail-container {
  --height: 400px;
}

// https://forum.ionicframework.com/t/determine-if-back-button-is-visible-hide-menu-button-when-back-button-is-visible/140459/11
// hide ion menu button if there's back button
.can-go-back {
  ion-menu-button {
    display: none;
  }
}

.ng2-tag-input__text-input {
  background-color: white;
}

.sc-ion-input-ios-h {
  --placeholder-color: initial;
  --placeholder-font-style: initial;
  --placeholder-font-weight: initial;
  --placeholder-opacity: 0.5;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --background: transparent;
  --color: initial;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 !important;
  background: var(--background);
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  z-index: 2;
}
ion-item.sc-ion-input-ios-h:not(.item-label),
ion-item:not(.item-label) .sc-ion-input-ios-h {
  --padding-start: 0;
}
.ion-color.sc-ion-input-ios-h {
  color: var(--ion-color-base);
}
.native-input.sc-ion-input-ios {
  border-radius: var(--border-radius);
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or
  (-webkit-margin-start: 0) {
  .native-input.sc-ion-input-ios {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--padding-start);
    padding-inline-start: var(--padding-start);
    -webkit-padding-end: var(--padding-end);
    padding-inline-end: var(--padding-end);
  }
}
.native-input.sc-ion-input-ios::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
  font-style: var(--placeholder-font-style);
  font-weight: var(--placeholder-font-weight);
  opacity: var(--placeholder-opacity);
}
.native-input.sc-ion-input-ios::-moz-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
  font-style: var(--placeholder-font-style);
  font-weight: var(--placeholder-font-weight);
  opacity: var(--placeholder-opacity);
}
.native-input.sc-ion-input-ios:-ms-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
  font-style: var(--placeholder-font-style);
  font-weight: var(--placeholder-font-weight);
  opacity: var(--placeholder-opacity);
}
.native-input.sc-ion-input-ios::-ms-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
  font-style: var(--placeholder-font-style);
  font-weight: var(--placeholder-font-weight);
  opacity: var(--placeholder-opacity);
}
.native-input.sc-ion-input-ios::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
  font-style: var(--placeholder-font-style);
  font-weight: var(--placeholder-font-weight);
  opacity: var(--placeholder-opacity);
}
.native-input.sc-ion-input-ios:-webkit-autofill {
  background-color: transparent;
}
.native-input.sc-ion-input-ios:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.native-input.sc-ion-input-ios::-ms-clear {
  display: none;
}
.native-input[disabled].sc-ion-input-ios {
  opacity: 0.4;
}
.cloned-input.sc-ion-input-ios {
  left: 0;
  top: 0;
  position: absolute;
  pointer-events: none;
}
[dir="rtl"].sc-ion-input-ios .cloned-input.sc-ion-input-ios,
[dir="rtl"].sc-ion-input-ios-h .cloned-input.sc-ion-input-ios,
[dir="rtl"] .sc-ion-input-ios-h .cloned-input.sc-ion-input-ios {
  left: unset;
  right: unset;
  right: 0;
}
.input-clear-icon.sc-ion-input-ios {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-position: center;
  border: 0;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  visibility: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.has-focus.has-value.sc-ion-input-ios-h .input-clear-icon.sc-ion-input-ios {
  visibility: visible;
}
.has-focus.sc-ion-input-ios-h {
  pointer-events: none;
}
.has-focus.sc-ion-input-ios-h input.sc-ion-input-ios,
.has-focus.sc-ion-input-ios-h a.sc-ion-input-ios,
.has-focus.sc-ion-input-ios-h button.sc-ion-input-ios {
  pointer-events: auto;
}
.sc-ion-input-ios-h {
  --padding-top: 10px;
  --padding-end: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  font-size: inherit;
}
.item-label-stacked.sc-ion-input-ios-h,
.item-label-stacked .sc-ion-input-ios-h,
.item-label-floating.sc-ion-input-ios-h,
.item-label-floating .sc-ion-input-ios-h {
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 0px;
}
.input-clear-icon.sc-ion-input-ios {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='var(--ion-color-step-600,%20%23666666)'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>");
  width: 30px;
  height: 30px;
  background-size: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;

  -webkit-text-fill-color: inherit !important;

  /* -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset; */

  transition: background-color 5000s ease-in-out 0s;
}

.background-image {
  --background: url("./assets/images/background.png") repeat;
}

ion-button {
  --background: linear-gradient(to right, #0191b3, #8ae3ff);
  --border-radius: 20px;
}

ion-card {
  border-radius: 25px;
}
